  /* Footer styling */
  footer {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 24px;
    margin-right: 20px;
  }
  
  