
/* Reset default margin, padding, and enable smooth scrolling */
* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }
  

  /* Header styling */
  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #ffffff; /* Change to your desired color */
    padding: 10px 20px;
    display: flex;
    align-items: center;
    
  }
  
  .logo img {
    max-width: 15%;
    max-height: 500px;

  }
  
  /* Hero section styling */
  .hero {
    position: relative;
    height: 800px;
    overflow: hidden;
  }
  
  .hero-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; /* Change to your desired color */
  }
  
  .hero-content h1 {
    font-family: "Dongle-bold";
    font-size: 4rem;
    line-height: 1.2;
  }
  
  .hero-content p {
    font-family: "Dongle-regular";
    font-size: 1.8rem;
    margin-top: -20px;
    margin-bottom: 10px;
    color: #130c2c;
  }
  
  .hero-button {
    display: inline-block;
    text-decoration: none;
    color: #fff; /* Change to your desired color */
    background-color: #ff7f50;
    padding: 12px 30px;
    border-radius: 50px;
    font-family: "Dongle-light";
    font-size: 1.6rem;
    transition: all ease 0.3s;
  }
  
  .hero-button:hover {
    background-color: #333; /* Change to your desired color on hover */
  }
  

  
  /* Media queries for responsiveness */
  @media (max-width: 1177px) {
    .hero-content h1 {
      line-height: 1;
    }
    .hero-content p {
      margin-top: 0;
      line-height: 0.8;
    }
  
    .destination-item img {
      height: auto;
    }
  
    input,
    textarea {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .hero-content h1 {
      margin-bottom: 15px;
    }
  
    .team-member img {
      width: 200px;
    height: auto;
    }
  
    .destination-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
    }
  }
  
  @media (max-width: 603px) {
    .hero-content h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
  
    .hero-content p {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 430px) {
    header {
      display: none;
    }
  
    .hero-content h1 {
      font-size: 1.3rem;
      margin-bottom: 10px;
    }
  
    .hero-content p {
      margin-top: 10px;
      font-size: 1rem;
    }
  
    .hero-button {
      padding: 6px 22px;
      font-size: 16px;
    }
  
    .destinations {
      font-size: 1.5rem;
    }
  
    .destination-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }
  
    .about {
      padding: 0 30px;
    }
  
    .team-member img {
      width: 250px;
      height: auto;
    }
  }
  /* Define keyframes for the animation */
@keyframes textAnimation {
  0% {
    font-size: 2.5rem;
    color: #130c2c; /* Initial color (light) */
  }
  50% {
    font-size: 3rem; /* Increase font size */
   
  }
  100% {
    font-size: 2.5rem;
    color: #130c2c; /* Back to initial color (light) */
  }
}

.hero-content h1 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  animation: textAnimation 4s infinite; /* Apply the animation to the text */
}

.hero-content p {
  font-family: "Dongle-regular";
  font-size: 2rem;
  margin-top: -20px;
  margin-bottom: 10px;
}

.hero-button {
  display: inline-block;
  text-decoration: none;
  color: #fff; /* Change to your desired color */
  background-color: #ff7f50;
  padding: 12px 30px;
  border-radius: 50px;
  font-family: "Dongle-light";
  font-size: 1.6rem;
  transition: all ease 0.3s;
}

.hero-button:hover {
  background-color: #333; /* Change to your desired color on hover */
}
/* Slideshow styling */
.slideshow-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    margin-top: 100px;
  }
  
  .mySlides {
    display: none;
  }
  
  .mySlides img {
    width: 100%;
    height: auto;
  }
  
  /* Hero section styling */
.hero {
  position: relative;
  height: 100vh; /* Set the hero section to cover the entire viewport height */
  overflow: hidden;
}

.hero-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; /* Change to your desired color */
}

.hero-content h1 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 4rem; /* Adjusted font size for better responsiveness */
  line-height: 1.2;
  animation: textAnimation 4s infinite;
}

.hero-content p {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.6rem; /* Adjusted font size for better responsiveness */
  margin-top: 10px; /* Slight adjustment for spacing */
  margin-bottom: 20px; /* Increased spacing for better readability */
}

.hero-button {
  display: inline-block;
  text-decoration: none;
  color: #fff; /* Change to your desired color */
  background-color: #ff7f50;
  padding: 12px 30px;
  border-radius: 50px;
  font-family: "Dongle-light";
  font-size: 1.6rem;
  transition: all ease 0.3s;
}

.hero-button:hover {
  background-color: #333; /* Change to your desired color on hover */
}

/* Media queries for responsiveness */
@media (max-width: 1177px) {
  .hero-content h1 {
    font-size: 2.5rem; /* Adjusted font size for medium-sized screens */
  }

  .hero-content p {
    font-size: 1.4rem; /* Adjusted font size for medium-sized screens */
  }
}

@media (max-width: 768px) {
  #heroContent{
    margin-top: 60px;
  }
  .hero-content h1 {
    font-size: 0rem; /* Adjusted font size for smaller screens */
  }

  .hero-content p {
    font-size: 1rem; /* Adjusted font size for smaller screens */
  }

  .hero-button {
    font-size: 1.4rem; /* Adjusted font size for smaller screens */
  }
}
/* CSS for the slideshow */
.slideshow-container {
  position: relative;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.mySlides {
  display: block;
  width: 100%;
}
/* Hero section styling */
.hero {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-content {
  text-align: center;
  color: #fff;
}

.hero-content h1 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 2rem;
  line-height: 1.2;
  animation: textAnimation 4s infinite;
}

.hero-content p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.6rem;
  margin-top: 10px;
  margin-bottom: 20px;
}

.hero-button {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #ff7f50;
  padding: 12px 30px;
  border-radius: 50px;
  font-family: "Dongle-light";
  font-size: 1.6rem;
  transition: all ease 0.3s;
}

.hero-button:hover {
  background-color: #333;
}

/* Media queries for responsiveness */
@media (max-width: 1177px) {
  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
 
  .hero{

   
  background-image: url("./images/back.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 700px;
  margin-top: 80px;
  }

  hero-content p {
    font-size: 0.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  hero-button {
    font-size: 1.4rem;
  }
}





