/* About section styling */
.about {
    text-align: center;
    padding: 10px;
  }
  
  .about h4 {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 3rem;
  }
  
  .about p {
    font-family: "Dongle-regular";
    font-size: 1.4rem;
    line-height: 1;
    margin-bottom: 40px;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .team-member img {
    width: 300px;
    height: auto;
    margin: 20px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s;
  }
  
  .team-member img:hover {
    transform: scale(1.1);
  }
  
  .team-member h4 {
    font-family: "Dongle-bold";
    font-size: 2rem;
  }
  