/* #back{
   
    background-image:url("https://c2.staticflickr.com/8/7430/9493012767_dcdbf62936_b.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height:650px
} */
#check{
margin-top: 60px;

}
#font{
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-size:50px;
}
.n {
color: #0c1032;
 font-size: 60px ;

}

/* Additional styling */

body {
font-size: 18px;
font-family: "Vibur", sans-serif;
background-color: #010a01;
}  

h1, h2 {
text-align: center;
text-transform: uppercase;
font-weight: 400;
}

h1 {
  font-size: 20rem;
}

.containe {
margin-top: 20vh;
}
.international{
  margin-bottom: 2px;
}

@media (max-width: 768px) {
 .n{
  font-size: 40px;

 }
}