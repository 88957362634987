.okcontainer{
  display: flex;
  justify-content: space-around;
}
.imagee{
  width:700px;
  height:450px;
  border-radius: 20px;
}
.imagediv{
  display: flex;
}
.formcontains {
 
    width: 300px; /* Use percentage for width to make it responsive */
    max-width: 300px;
     /* Set a maximum width to avoid stretching on larger screens */
    height: auto;
    background-color: rgb(213, 201, 201);
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 25px;
  }
  
  @media (max-width: 1080px) {
    .okcontainer
    {
      display: block;/* Stack items vertically on small screens */
   
    }
    .imagediv{
      display: flex;
      justify-content: center;
    }
    .myform{
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
 
}
@media (max-width: 750px){
  .imagee{
    width:500px;
    height:300px;
    border-radius: 20px;
  }
}
@media (max-width: 600px){
  .imagee{
    width:350px;
    height:250px;
    border-radius: 20px;
  }
}



  
  .timelinecontainer {
    display: flex;
    width: 100%; /* Use percentage for width to make it responsive */
    max-width: 700px; /* Set a maximum width to avoid stretching on larger screens */
    height: auto;
    background-color: white;
   
    
    /* Add any additional styling as needed */

  }
  
  
  table {
    border-collapse: collapse;
    width: 100%; /* Adjust the width as needed */
  }

  table, th, td {
    border: 1px solid rgba(227, 221, 221, 0.903);
  }

  th, td {
    padding: 10px;
    text-align: left;
  }