.dropdown:hover .dropdown-menu {
    display: block;
  }
  #contactbar{
    background-color: rgb(93, 192, 65);
   margin-top: 152px;
   padding: 1px ;
   display: flex;
   height: 35px;
     
  
  }

  @media (max-width: 550px) {
    #contactbar{
      margin-top: 200px;
    }
    .heading{
      font-size:20px;
    }
   
  }
  