/* ChardhamPackages.css */

.package-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .package-container {
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
    }
    .package-card {
      width: 100%; /* Take full width on smaller screens */
      height: auto;
      padding-right: 40px;
      margin-bottom: 20px; /* Add some spacing between cards */
    }
  }