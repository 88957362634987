/* #back1{
   
  background-image: url("https://www.hdwallpapers.in/download/the_mountain_4k_hd-HD.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height:650px

} */
#check{
    margin-top: 60px;

}
#font{
 font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 font-size:50px;
}
.neonText {

     font-size: 60px ;
  
  }
  
  /* Additional styling */
    
  body {
    font-size: 18px;
    font-family: "Vibur", sans-serif;
    background-color: #010a01;
  }  
  
  h1, h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
  }
    
  h1 {
      font-size: 20rem;
  }
    
  .containe {
    margin-top: 20vh;
  }
  @media (max-width: 768px) {
    .neonText{
     font-size: 40px;
   
    }
   }