/* PackageCard.css */

.card1 {
  width: calc(33.33% - 20px); /* Initial width */
  height: 480px; /* Initial height */
  margin: 20px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: scale(1.1); /* Increase size on hover */
  }

  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .card1 {
    width: calc(50% - 20px); /* Adjust width for smaller screens */
  }
}

@media (max-width: 576px) {
  .card1 {
    width: 100%; /* Full width for even smaller screens */
  }
}

.card-image1 {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
}

.container1 {
  display: flex;
  flex-direction: column; /* Stack items vertically on small screens */
  align-items: center;
}

.card-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 16px;
  text-align: center; /* Center the text on small screens */
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.read-more-btn {
  display: block;
  padding: 8px 12px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
}


/* PackageCard.css */
.card1 {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.card1:hover {
  transform: scale(1.05);
}

.card-image1 {
  width: 100%;
  height: 300px;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card1:hover .overlay {
  opacity: 1;
}

.overlay button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.card-content {
  position: relative;
}

.container1 {
  position: relative;
}

.card-title,
.card-description,
.right {
  position: relative;
  z-index: 1;
}

